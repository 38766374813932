(function() {
  function GameTeamManagement() {
    this.init = init;
    this.teamCounterWatch = teamCounterWatch;

    var selectedClass = "team-selected",
        apiPath = '/team/api/v1/teammembership/',
        self = this;

    /* Initialize and point it to a container (a table, div, etc) that has
       rows of team membership data.
     */
    function init(teamListContainer) {
      ajaxSetup();
      bindEvents(teamListContainer);
    }

    /* Delete all the team memberships found in the given team membership container div
    *
    * returns a list of async objects that will indicate when the team memberships have been successfully deleted
    * */
    function deleteContainerMemberships(container) {
        var asynchs = [];

        container.find('.'+selectedClass).each(function(idx, item) {
            var teamBox = $(item),
                teamMembershipId = teamBox.attr('data-membership-id'),
                lineupEl = container.parents('[data-lineup-id]'),
                lineupId = lineupEl.attr('data-lineup-id'),
                userId = $(container).attr('data-user-id'),
                teamId = teamBox.attr('data-team-id');

            teamBox.removeClass(selectedClass);

            asynchs.push($.ajax(apiPath + teamMembershipId +'/',
                {
                    type: 'DELETE',
                    dataType: 'text',
                    success: function () {
                        $(container).trigger("delete-team-membership", [userId, lineupId, teamId]);
                    }
                }));
        });

        return asynchs;
    }

    /* Set a new team membership for the given container
    *
    * returns an async object that indicates when the new team membership has been successfully
    * recorded in the api
    * */
    function addContainerMembership(container, teamId, teamEl) {
        var userId = container.attr('data-user-id'),
            lineupEl = container.parents('[data-lineup-id]'),
            lineupId = lineupEl.attr('data-lineup-id'),
            postData = {
                'team': teamId,
                'user': userId
            };

        $(this).addClass(selectedClass);

        return $.ajax({
            type: 'POST',
            url: apiPath,
            data: JSON.stringify(postData),
            // we wont get anything back, so don't try to parse it as json
            dataType: 'text',
            complete: function () {
                // figure out the membership id of the new entry
                $.get(apiPath + "?team="+teamId+"&user="+userId, function (response) {
                    if (response.hasOwnProperty('objects')) {
                        var memRec = response.objects[0];

                        // record the new membership id
                        $(teamEl).attr('data-membership-id', memRec.id).addClass(selectedClass);

                        // send an event for a new team membership
                        $(container).trigger("add-team-membership", [userId, lineupId, teamId]);
                    }
                });
            }
        });
    }

    // run all the ajax to switch to a new team id
    function ajaxSwitchTeam(container, newTeamId, newTeamEl) {
        var deferreds,
            minTimeDeferred = new $.Deferred(),
            newTeamEl = $(container).find('[data-team-id="'+newTeamId+'"]');

        $(container).addClass('is-loading');

        // remove all existing team memberships
        // returns multiple deferreds
        deferreds = deleteContainerMemberships(container);

        // set a new team membership
        // returns a single deferred
        deferreds.push(addContainerMembership(container, newTeamId, newTeamEl));

        // force the whole thing to take at least 1 second
        setTimeout(function () { minTimeDeferred.resolve(); }, 1000);
        deferreds.push(minTimeDeferred);

        $.when.apply($, deferreds).done(function() {
            $(container).removeClass('is-loading');
        });
    }

    function teamSelectBoxClick(event) {
        var container = $(this).parents('.team-select-container'),
            teamId = $(this).attr('data-team-id');

        ajaxSwitchTeam(container, teamId);
    }

    // determine currently selected team id, determine next team id in sequence. Switch to that team
    function cycleTeams(container) {
        var ids = $(container).attr('data-team-ids').split(","),
            selectedBox = container.find('.'+selectedClass).first(),
            selectedId = selectedBox.attr('data-team-id'),
            i,
            nextId;

        // now determine the next appropriate team in the sequence of team ids
        for (i=0; i < ids.length && ids[i] != selectedId; i++) { }

        if (i >= ids.length - 1)
            i = 0;
        else
            i = i + 1;

        nextId = ids[i];

        // run the code to set the new membership
        ajaxSwitchTeam(container, nextId);
    }

      function teamCycleClick(event) {
        var container = $(this).parents('.team-select-container');

        event.preventDefault();

        cycleTeams(container);
    }

    function bindEvents(container) {
        $(container).find(".team-select-container .team-indicator").click(teamSelectBoxClick);
        $(container).find("p.team-cycle a").click(teamCycleClick);
    }

    /* Setup a watcher of team select boxes so that when user changes team selections,
       the team counters (totals) will be updated

       table_selector - jquery selector for team counter table
       button_container_selector - jquery selector for container that holds team select buttons
     */
    function teamCounterWatch(table_selector, button_container_selector) {
        var counter_table = $(table_selector),
            button_container = $(button_container_selector);

        // anytime we decrement a team membership, decrement the right team counter(s)
        button_container.find(".team-select-container").
            on("add-team-membership", function (event, user, lineup, team) {
                teamCounterUpdate(counter_table, team, lineup, 1);
            }).
            on("delete-team-membership", function (event, user, lineup, team) {
                teamCounterUpdate(counter_table, team, lineup, -1);
            });
    }

    // add or delete from the given team and lineup
    function teamCounterUpdate(counterTable, teamId, lineupId, incVal) {
        var td=$('[data-team-id="'+teamId+'"][data-lineup-id="'+lineupId+'"]'),
            cnt = parseInt(td.text());

        td.text(cnt + incVal);
    }

    function ajaxSetup() {
      $.ajaxSetup({
        processData: false,
        dataType: 'json',
        contentType: 'application/json',
        beforeSend: function(xhr) {
          xhr.setRequestHeader('X-CSRFToken', CSRF_TOKEN);
        }
      });
    }
  }
  TSk.GameTeamManagement = GameTeamManagement;
  TSk.gameTeamManager = new GameTeamManagement;
})();



