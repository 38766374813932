$(function() {

  $('[data-dismiss="alert"]').click(function() {
    $(this).closest('.alert').remove();
  });

  $('#cost, #players').change(function() {
    var totalCost = parseInt($('#cost').val(), 10),
        players = parseInt($('#players').val(), 10),
        costList = {};

        if (totalCost<=0 || isNaN(totalCost)){

            $(".erro-num-cost").remove();
            $("#cost").after('<p class="erro-num-cost text-error"><strong>This data is invalid.</strong></p>')
        }
        
        else{
             $(".erro-num-cost").remove();
        }

        if (players<=0 || isNaN(players)){

            $(".erro-num-players").remove();
            $("#players").after('<p class="text-error erro-num-players"><strong>This data is invalid.</strong></p>')
        }
        
        else{
             $(".erro-num-players ").remove();
        }

        costList = {
          twenty: (totalCost/20).toFixed(2),
          other: (totalCost/players).toFixed(2)
        };

        var diff = (costList.other - costList.twenty).toFixed(2);

    if(costList.twenty <=0 || isNaN(costList.twenty))
    {
        $('.calc-20skater-cost').text("NA");
    }
    else
    {
        $('.calc-20skater-cost').text(costList.twenty);
    }


    if(costList.other <=0 || isNaN(costList.other) || costList.other == Infinity )
    {
        $('.calc-cost').text("NA");
    }
    else
    {
        $('.calc-cost').text(costList.other);
    }


    if(diff <0 || isNaN(diff) || diff == Infinity)
    {
        $('.calc-diff').text("NA");
    }
    else
    {
        $('.calc-diff').text(diff);
    }


    if((diff * 20).toFixed(2) <=0 || isNaN((diff * 20).toFixed(2)))
    {
        $('.calc-season').text("NA");
    }
    else
    {
        $('.calc-season').text((diff * 20).toFixed(2));
    }


    if(diff<0 || (diff * 20 * players).toFixed(2)<0 || isNaN((diff * 20 * players).toFixed(2)))
    {
        $('.calc-organizer').text("NA");
    }
    else
    {
        $('.calc-organizer').text((diff * 20 * players).toFixed(2));
    }


  });

  $('time').each(function() {
    var datetime = $(this).attr('datetime'),
        date = Date.parse(datetime);

    $(this).text(date.format('%B %d, %Y, %r'));
  });

  $('.js-logout').click(
      function (event) {
          event.preventDefault();


          $.ajax({url: $(this).attr('href'),
              data: {
                  csrfmiddlewaretoken: CSRF_TOKEN
              },
              async: false,
              type: "post"
          });
          window.location = '/';
      }
  );

});
